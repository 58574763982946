import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Error } from './error/index'
import Product from './product/index';
import {ProductIsbn, fetchIsbn} from './product/isbn/index'
import reportWebVitals from './reportWebVitals';
import {
  ReactLocation,
  Router
} from "@tanstack/react-location";
import axios from "axios";
const location = new ReactLocation();

const App = () => {
  return (<Router
    location={location}
    routes={[
      { path: "/", element: <Index /> },
      {
        path: "product",
        children: [
          {
            path: "/",
            element: <Product />
          },
          {
            path: ":isbn",
            element: <ProductIsbn />,
            loader: async ({ params: { isbn }}) => {
              return {
                link: await fetchIsbn(isbn),
              };
            }
          }
        ]
      },
      {
        path: "error",
        element: <Error />
      }
    ]}
  >

  </Router>);
};

const Index = () => {
  return (
    <div>
      <h3>Welcome Home!</h3>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();