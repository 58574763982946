import { useEffect } from 'react';
import axios from "axios";
import {
  MakeGenerics,
  useMatch
} from "@tanstack/react-location";
type LocationGenerics = MakeGenerics<{
  LoaderData: {
    link: string;
  };
}>;


export const fetchIsbn = async (isbn: string) => {
  // API取得
  console.log(isbn);
  const link = await axios.get("https://api.kw-dstore.com/ec/product/link", {
    params: {
      "isbn": isbn
    }
  })
    .then(res => {
      return res.data.link;
    });
  console.log(link);
  return link;
}

export const ProductIsbn: React.FC = () => {
  const {
    data: { link }
  } = useMatch<LocationGenerics>();
  window.location.href = link ?? "";
  return (
    <div className="App">
      <header className="App-header">
      </header>
      <Index />
    </div>
  );
}

const Index = () => {
  useEffect(() => {
    document.title = '超ダビンチストアアプリへ遷移';
  });
  return (
    <div className='content'>
      <h2 className='desc'>超・ダヴィンチストア決済アプリ」に移動します…</h2>
    </div>
  )
}