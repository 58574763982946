import { useEffect } from 'react';

export const Error = () => {
    useEffect(() => {
        document.title = '遷移に失敗しました';
    });
    return (
        <div className='content'>
            <h2 className='desc'>
                アプリの起動に失敗しました、もう一度QRコード読み込みをお試しください<br />
                何度も失敗する場合、<a href="https://forms.gle/eebJ4c2QgaRxikTw8">お問い合わせ</a>をしてください
            </h2>
        </div>
    )
}