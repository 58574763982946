import React from 'react';

function Product() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Edit <code>src/Product.tsx</code> and save to reload.
        </p>
      </header>
    </div>
  );
}

export default Product;